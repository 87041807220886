.mobileSearchRoot {
    display: flex;
    padding: 3px 6px 3px 6px;
    width: 100%;
    max-width: 320px;
    min-width: 200px;
    height: 35px;
    background: white;
    box-shadow: 5px 5px 21px 4px #00000014;
    border-radius: 30px;
    justify-content: flex-start;
    align-items: center;

    .icon {
        font-size: 17px;
        color: rgba(33, 33, 33, 0.6);
    }

    .input {
        background: transparent;
        font-size: 13px;
        font-family: 'century_gothic';
        width: 100%;
        outline: none;
        border: none;
    }

    .input::placeholder {
        font-family: 'century_gothic';
        color: #00000066;
        font-size: 12px;
    }
}
