.payroll-card-container {
  width: 100%;
  height: 102px;
  background-color: #007e631a;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.payroll-card-edit-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 24px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007e63;
}

.payroll-card-edit-icon {
  color: white;
  font-size: 16px;
}

.payroll-card-title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 16.94px;
  color: #007e63;
  margin-bottom: 10px;
}

.payroll-card-des-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.payroll-card-des-mini-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.payroll-card-des-bold {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.06px !important;
  color: black;
  font-family: "Roboto", sans-serif !important;
}

.payroll-card-des-normal {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.06px !important;
  color: #787878;
  font-family: "Roboto", sans-serif !important;
}
