.RoleCard {
  color: #212121;
  text-decoration: none;
  // max-width: 400px;
  width: 100%;
  min-height: 57px;
  max-height: auto;
  border-radius: 16px;
  // background: #eef2fb;
  background: #EEF2FB;

  article {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    align-items: center;

    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // min-width: 270px;
      // max-width: 300px;

      > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: start;

        > div:first-child {
          display: flex;
          gap: 10px;
          align-items: center;

          > div:first-child {
            font-size: 16px;
            font-weight: 500;
            line-height: 18.75px;
            font-family: "Roboto, sans-serif";
            color: #000000b2;
          }

          > div:last-child {
            display: flex;
            justify-content: center;
            align-items: end;
            gap: 2px;

            svg {
              font-size: 16px;
            }

            div {
              font-size: 12px;
            }
          }
        }

        > div:nth-child(2) {
          font-size: 12px;
          font-weight: 400;
          color: #787878d9;
        }

        > div:last-child {
          display: flex;
          gap: 1px;
          justify-content: start;

          div {
            font-size: 10px;
          }
        }
      }
    }

    svg {
      font-size: 16px;
    }
  }
}

.cardSearch {
  color: #212121;
  text-decoration: none;
  // max-width: 400px;
  width: 100%;
  min-height: 57px;
  max-height: auto;
  border-radius: 16px;
  background: #eef2fb;
  //background: rgba(0, 126, 99, 0.25);
  article {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    align-items: center;

    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // min-width: 270px;
      // max-width: 300px;

      > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: start;

        > div:first-child {
          display: flex;
          gap: 10px;
          align-items: center;

          > div:first-child {
            font-size: 16px;
            font-weight: 500;
            color: #000000b2;
          }

          > div:last-child {
            display: flex;
            justify-content: center;
            align-items: end;
            gap: 2px;

            svg {
              font-size: 16px;
            }

            div {
              font-size: 12px;
            }
          }
        }

        > div:nth-child(2) {
          font-size: 12px;
          font-weight: 400;
          color: #787878d9;
        }

        > div:last-child {
          display: flex;
          gap: 1px;
          justify-content: start;

          div {
            font-size: 10px;
          }
        }
      }
    }

    svg {
      font-size: 16px;
    }
  }
}
