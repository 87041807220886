.mvNewsTopNavWithBackRoot {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    position: fixed;
    padding: 10px;
    height: 56px;
    margin-bottom: 30px;
    align-items: center;
    background: white;
    // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid #D9D9D9;
    z-index: 99;

    .backArrowIcon {
        position: absolute;
        left: 10px;
        top: 19px;
        font-size: 17px;
        color: #b80000;
        cursor: pointer;
    }

    .Text {
        // font-weight: 500;
        font-family: 'inter-bold';
        color: #000000;
    }
}

.mvNewsTopNavDefaultRoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    gap: 10px;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 0px 26px 10px 26px;

    .contentContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 40px;
        align-items: center;
        margin-top: 5px;

        .greetingsWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            img {
                width: 16px;
                height: 16px;
            }

            div {
                font-size: 16px;
                font-weight: 700;
                color: white;
            }
        }

        .iconWrapper {
                width: 28px;
                height: 28px;
                background: rgba(255, 255, 255, 0.4);
                border-radius: 50%;
                display: flex;
                justify-content: center ;
                align-items: center;
    
                .newsMobileTopNavDashboardIcon {
                    margin-top: -5px;
                    font-size: 20px;
                    color: white;
                }
        }
    }

    .searchAndIconContainer {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        // padding-bottom: 15px;
        align-items: center;
        backdrop-filter: blur(10px);
        z-index: 9999;

        .icon {
            font-size: 25px;
            // color: white;
        }
    }
}
