.eventDetailsTopNavRoot {
  width: 100%;
  height: 140px;
  z-index: 9999;
  position: fixed;
  background: white;
  top: 0;
  .eventDetailsTopNavContainer {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    padding: 14px;
    justify-content: space-between;
    .cornerPatternImageContainer {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 88px;
      height: 88px;

      img {
        width: 88px;
        height: 88px;
      }
    }

    .detailContainer {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 2px;
      align-items: start;

      .title {
        font-size: 22px;
        color: black;
        font-weight: 500;
      }
      .date {
        font-size: 14px;
        font-weight: 400;
        color: #000000b2;
      }
    }
  }
}

.hrMobileHeader {
  width: 100%;
  display: flex;
  position: fixed;
  padding: 10px 16px;
  height: 100px;
  margin-bottom: 30px;
  align-items: center;
  background: white;
  justify-content: start;
  z-index: 99;
  top: 0;
  right: 0;
  left: auto;
  flex-shrink: 0;

  .headerContainerHR {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .profileContainerHR {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 16px;

      .profileImageHR {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .profileImg {
          width: 26px;
          height: 26px;
        }
      }

      .userInfoContainerHR {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: start;
        gap: 1px;

        .userNameHR {
          font-size: 18px;
          font-weight: 700;
          color: black;
          font-family: "Roboto", sans-serif;
          line-height: 21.09px;
        }

        .welcomeTextHR {
          font-size: 12px;
          color: #78787880;
          font-family: "Roboto", sans-serif;
          line-height: 14.06px;
        }
      }
    }

    .searchIconContainerHR {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .searchIcon {
        font-size: 16px;
      }
    }
  }
}

.hrMobileHeaderRoot {
  width: 100%;
  display: flex;
  position: fixed;
  padding: 10px;
  height: 56px;
  margin-bottom: 30px;
  align-items: center;
  background: white;
  box-shadow: 0px 1px 4px 0px #0000001f;
  justify-content: center;
  z-index: 99;

  .backButton {
    position: absolute;
    left: 20px;

    .title {
      font-size: 14px;
      font-weight: 500;
      color: #212121bf;
    }
  }
}

.EventListingHeader {
  background: white;
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 30px;
  font-size: 20px;
  color: black;
  font-weight: 700;
}

.hrMobileBordingListRoot {
  width: 100%;
  height: 230px;
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 99;
  background-color: white;

  .hrBoardingListTitleContaier {
    box-shadow: 0px 1px 4px 0px #0000001f;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 56px;
  }

  .hrMobileBoardingHeader {
    width: 100%;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 41px;
      padding: 0rem 1.5rem;
      margin-bottom: 70px;

      .toggleButton {
        display: flex;
        border-radius: 50px;
        background-color: #e3f2f1;
        width: fit-content;
        height: 36px;
        width: 204px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.06px;
        font-family: "Roboto", sans-serif;
        border: 1px solid #007E63;


        .toggleOption {
          border: none;
          border-radius: 50px;
          background-color: transparent;
          color: #007e63;
          font-size: 12px;
          font-weight: 500;
          line-height: 15.06px;
          font-family: "Roboto", sans-serif;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
          height: 30px;
          width: 98px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .toggleOption:hover:not(.active) {
          background-color: #007e631a;
        }

        .active {
          background-color: #007e63;
          color: white;
          height: 30px;
        }
      }

      .boradingSearchFilterContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 41px;

        .searchIconContainerHR {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
      
            .searchIcon {
              font-size: 16px;
            }
          }

        .filterIcon {
            font-size: 22px;
          }
      }
    }
  }
}
