.news-bottom-appbar {
  height: 55px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  .tabs {
    display: flex;
    flex-direction: row;
    height: 100%;
    .tab {
      cursor: pointer;
      &--left {
        border-top-right-radius: 20px;
        z-index: 30;
      }
      &--right {
        border-top-left-radius: 20px;
        z-index: 30;
      }
      &--fab {
        width: 53px;
        height: 100%;
        background: transparent;
        border: none;
        display: flex;

        .top {
          width: 55px;
          height: 26px;
          z-index: 20;
          border-bottom-left-radius: 110px;
          border-bottom-right-radius: 110px;
          box-shadow: 10px 25px 0px 25px #b80000;
          display: flex;
        }
      }
      background: #b80000;
      width: 47%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 24px;
      span {
        font-size: 12px;
      }
      i {
        font-size: 15px;
      }
      &.is-active {
        color: #ffffff;
      }
    }
    .fab {
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #b80000;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-weight: bold;
      font-size: 22px;
      color: #fff;
      position: relative;
      justify-content: center;
      transform: translate(7px, -55%);
    }
  }
}
