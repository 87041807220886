.courseMobileRoot {
  width: 100%;
  height: 100%;
  background: #f8f6ff;
  .courseMobileMainContainer {
    padding-top: 80px;
    width: 100%;
    height: 100%;
    background: #f8f6ff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // overflow: hidden;
    margin-bottom: 70px;

    .courseMobileToggleContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .courseMobileLoadingContainer {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      background: transparent;
    }
    .courseMobileLessonContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      padding: 10px;
      gap: 14px;
      width: 100%;

      .courseMobileTextWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: 16px;
        text-align: center;
        width: 100%;
      }
    }
    .courseMobilePaginationContainer {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
