.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 41px;
  padding: 0rem 1.5rem;
  margin-bottom: 70px;
}

.toggleButton {
  display: flex;
  border-radius: 25px;
  background-color: #e3f2f1;
  width: fit-content;
  height: 38px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  font-family: "Roboto", sans-serif;
}

.toggleOption {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: transparent;
  color: #007e63;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  height: 38px;
}

.toggleOption:hover:not(.active) {
  background-color: #007e631a;
}

.active {
  background-color: #007e63;
  color: white;
  height: 38px;
}

.content {
  margin-top: 20px;
  width: 100%;
  /* text-align: center; */
}

.payrollDescContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
}

.payrollDescFirstRow,
.payrollDescSecondRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.payrollDescThirdRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.payrollDescThirdRowLeft {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.payrollDescThirdRowRight {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
}

.payrollColoredText {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: #007e63;
  font-family: "Roboto", sans-serif;
}

.payrollText {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: black;
  font-family: "Roboto", sans-serif;
}

.payroll-edit-icon {
  font-size: 16px;
  margin-top: 3px;
  color: #007e63;
}

.payroll-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

