.breadcrumb-container {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  border-radius: 10px;
  padding: 5px;
}

.analytics-breadcrumb-back-icon {
  font-size: 35px;
  color: #121212c8;
  font-weight: 700;
  cursor: pointer;
  background-color: #fe5f371a;
  padding: 4px;
  border-radius: 100px;
  margin-right: 20px;
}

.breadcrumb-item-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #fe5e37;
  gap: 10px;
}

.arrow-span {
  color: black;
  font-weight: bold;
  font-size: 16px;
}
